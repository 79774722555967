<div *ngIf="(isAuthenticated | async) === false || (isAuthenticated | async) === null" class="is-relative">
  <div class="top-tile">
  </div>
  <div class="tile is-12 is-relative mt-6">
    <article class="login">
      <section class="section mt-6">
        <h1>Your new UKHO Data Upload <br/>service</h1>
        <p class="sub-heading pb-2">There's a new way to send us bathymetry data.</p>
        <admiralty-button aria-label="Request an account"
                          [type]="'button'"
                          class="request-account pr-4"
                          (click)="requestAccount()"
                          variant="primary">Request an account</admiralty-button>

        <admiralty-button id="logIn"
                          aria-label="Sign in"
                          class="sign-in"
                          [type]="'button'"
                          (click)="login()"
                          variant="secondary">Log in<admiralty-icon class="pl-4" iconName="arrow-right"></admiralty-icon>
        </admiralty-button>
      </section>
    </article>
  </div>
  <div class="tile is-12 pt-6">
    <article class="description">
      <section class="section">
        <p class="main-desc mt-6">Our new UKHO Data Upload service is an online form that captures all the information we need to quickly and accurately process the bathymetry surveys you send us.</p>
        <p class="main-desc">The new form collects the same information as before, but in an easy to use digital format that you can track.</p>

        <h2 class="intro-title is-bold pt-5">Using the new service</h2>
        <p>During this exclusive launch to selected users, we invite you to submit all bathymetry surveys through the new UKHO Data Upload service.</p>
        <p>Once you have sent us a survey through the UKHO Data Upload service, this will become the default way you send your data to us.</p>

        <h3 class="desc-title pt-5">Logging into the new service</h3>
        <p>Selected users will already have an email from the UKHO explaining how to register for an account. To log into the new service, you’ll need the email address and password linked to your UKHO single sign on account.</p>

        <ul>
          <li>If you’re an existing user and you’ve forgotten your password, select the 'forgotten password' link and we'll send you a verification code to access your account.</li>
          <li>If you haven’t received an invite but you’d like to become a user, contact customer services to request an account: <a href="mailto:customerservices@ukho.gov.uk"><strong>customerservices@ukho.gov.uk</strong></a>.</li>
        </ul>

        <h2 class="intro-title is-bold pt-6">Why the way you send us bathymetric data has changed</h2>
        <p>The bathymetric data you share with the UKHO is very important. It helps to keep our seas safe.</p>
        <p>We’ve listened to your feedback and made it quicker and easier for you to send us your data.</p>
        <p>Our new data upload service is:</p>

        <ul>
          <li>More accurate and consistent</li>
          <li>Time saving</li>
          <li>Easier to understand and navigate</li>
        </ul>

        <h3 class="desc-title pt-5">Future service updated</h3>
        <p>We’re constantly improving our services to give you a better experience.</p>

        <p>In the future, there are plans to expand the service to other types of data. Eventually this will allow you to send all data through the UKHO Data Upload.</p>

        <h2 class="intro-title is-bold pt-5">Support with the new UKHO Data Upload service</h2>
        <p>For help logging in or uploading and sharing data through the new service, contact our customer services team:
          <br/><a href="mailto:customerservices@ukho.gov.uk"><strong>customerservices@ukho.gov.uk</strong></a>
        </p>

        <h3 class="desc-title pt-5">New service feedback</h3>

        <p>We’d like you to tell us about your experience using our new Data Upload service.</p>

        <p>There are opportunities to do this while you are using the service, but you can also contact us directly by emailing
         <br/><a href="mailto:customerservices@ukho.gov.uk"><strong>customerservices@ukho.gov.uk</strong></a>.</p>
      </section>
    </article>
  </div>
</div>
