import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { emailMatchValidator } from '../../validators/email-match.validator';
import { RequestAccountControls } from '../../models/metadata.model';
import { ValidationMatrix } from '../../models/validation-matrix.model';
import { RequestAccountValidation } from '../../page-validations/request-account.validation';

@Component({
  selector: 'ukho-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.scss']
})
export class RequestAccountComponent {
  protected readonly RequestAccountValidation = RequestAccountValidation;
  public form: RequestAccountControls;
  public showSuccessPage = false;
  public emailClicked = false;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      organisationName: ['', RequestAccountValidation.OrganisationName.validators],
      website: ['', RequestAccountValidation.WebsiteUrl],
      firstName: ['', RequestAccountValidation.FirstName.validators],
      lastName: ['', RequestAccountValidation.LastName.validators],
      jobTitle: ['', RequestAccountValidation.JobTitle.validators],
      emailDetails: this.fb.group(
        {
          email: ['', RequestAccountValidation.EmailAddress.validators],
          verifyEmail: ['', RequestAccountValidation.VerifyEmailAddress.validators]
        },
        {
          validators: [emailMatchValidator('email', 'verifyEmail')]
        }
      )
    }) as RequestAccountControls;
  }

  public sendRequest() {
    this.emailClicked = true;
    if (!this.form.invalid) {
      const recipient = 'CustomerServices@UKHO.gov.uk';
      const subject = 'Request for UKHO Data Upload account';
      const website =
        this.form.controls.website.value === '' || this.form.controls.website.value === null
          ? ''
          : ` \nWebsite: ${this.form.controls.website.value}`;
      const template = `Organisation name: ${this.form.controls.organisationName.value}${website} \n \nFirst name: ${this.form.controls.firstName.value} \nLast name: ${this.form.controls.lastName.value} \nJob title: ${this.form.controls.jobTitle.value} \nEmail: ${this.form.controls.emailDetails.controls.verifyEmail.value}`;
      const mailToUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        template
      )}`;
      window.location.href = mailToUrl;
      this.showSuccessPage = true;
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  public handleOnPaste = (): boolean => false;

  public validateMessage(
    formControl: FormControl | AbstractControl,
    matrix: ValidationMatrix,
    isField: boolean
  ): string {
    if (formControl.hasError('required')) {
      return isField
        ? matrix.validationDetails.requiredDetail.fieldMessage
        : matrix.validationDetails.requiredDetail.bannerMessage;
    }

    if (formControl.hasError('maxlength')) {
      return isField
        ? matrix.validationDetails.maxLengthDetail.fieldMessage
        : matrix.validationDetails.maxLengthDetail.bannerMessage;
    }

    if (formControl.hasError('pattern')) {
      return isField
        ? matrix.validationDetails.patternDetail.fieldMessage
        : matrix.validationDetails.patternDetail.bannerMessage;
    }

    if (formControl.hasError('leadingWhitespace')) {
      return isField
        ? matrix.validationDetails.leadingWhitespaceDetail.fieldMessage
        : matrix.validationDetails.leadingWhitespaceDetail.bannerMessage;
    }

    if (formControl.hasError('invalidEmail')) {
      return isField
        ? matrix.validationDetails.invalidEmailDetail.fieldMessage
        : matrix.validationDetails.invalidEmailDetail.bannerMessage;
    }

    if (formControl.hasError('emailMismatch')) {
      return isField
        ? matrix.validationDetails.emailMismatchDetail.fieldMessage
        : matrix.validationDetails.emailMismatchDetail.bannerMessage;
    }
  }
}
