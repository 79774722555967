import { EnvironmentConfig } from '../app/models/environment-config.model';

export const environment: EnvironmentConfig = {
  production: true,
  maintenanceMode: 'false',
  ccEmails: 'bdc.surveys@ukho.gov.uk',
  clientId: 'fe31ae17-5d04-49b7-b8ec-d27f462b7c7b',
  apiClientId: 'd6678a0b-65bb-484c-9e0a-49af4b4b5198',
  dataUploadApiEndpoint: 'https://api-ukhodataupload.admiralty.co.uk',
  authorityDomain: 'identity.admiralty.co.uk',
  baseAuthorityDomain: 'https://identity.admiralty.co.uk/e92c981b-d689-4cc3-8245-0d710e246bca',
  b2cScopeBaseUrl: 'https://mgiaidprdb2c.onmicrosoft.com',
  elasticApmConfig: {
    serviceName: 'External Data Upload UI',
    environment: 'Live',
    serverUrl: 'https://ukho.apm.uksouth.azure.elastic-cloud.com',
    serviceVersion: '0.0.0',
    logLevel: 'info',
    distributedTracingOrigins: [
      'https://identity.admiralty.co.uk',
      'https://api-ukhodataupload.admiralty.co.uk',
      'https://eduapplivesalandingzone.blob.core.windows.net'
    ]
  }
};
